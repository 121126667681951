<template>
  <div>
    <v-form
      ref="loginForm"
      id="login-form"
      style="max-width: 300px"
      class="mx-auto py-4 text-center"
      v-model="valid"
      :lazy-validation="true"
      @submit.prevent="login"
    >
      <v-text-field
        v-model="email"
        label="Email"
        :rules="[rules.required, rules.email]"
      ></v-text-field>
      <v-text-field
        label="Password"
        v-model="password"
        :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
        :type="passwordShow ? 'text' : 'password'"
        @click:append="passwordShow = !passwordShow"
        :rules="[rules.required]"
      ></v-text-field>
      <v-btn type="submit" color="primary darken-1" class="mt-3" dark form="login-form"
        >Log in</v-btn
      >
      <!-- <div class="mt-6 mb-3">
        <span
          class="primary--text text--darken-2 text-decoration-underline"
          style="cursor: pointer"
          @click="forgetPasswordDialog = true"
          >Forgot Password?</span
        >
      </div> -->
    </v-form>
    <!-- Dialog boxes -->
    <v-dialog v-model="forgetPasswordDialog" width="360" persistent>
      <v-card>
        <v-card-title class="subtitle-1 accent darken-3 white--text">
          Enter your registered Email ID
        </v-card-title>

        <v-card-text style="max-height: 60vh; overflow-y: auto">
          <v-form
            ref="forgetForm"
            id="forget-form"
            style="max-width: 300px"
            class="mx-auto py-4"
            v-model="forgetValid"
            :lazy-validation="true"
            @submit.prevent="sendForgetEmail"
          >
            <v-text-field
              v-model="forgetEmail"
              label="Email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="txt-dark darken-1"
            text
            @click="
              forgetEmail = '';
              forgetPasswordDialog = false;
            "
            >Cancel</v-btn
          >
          <v-btn type="submit" form="forget-form" small color="secondary"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from '@/utils/formRules'

export default {
  data: () => ({
    email: '',
    password: '',
    forgetEmail: '',
    valid: true,
    forgetValid: true,
    rules: {
      required,
      email
    },
    passwordShow: false,
    forgetPasswordDialog: false
  }),
  methods: {
    ...mapActions(['signin', 'sendResetPasswordEmail']),
    async login () {
      if (this.$refs.loginForm.validate()) {
        this.showSpinner()
        try {
          await this.signin({
            type: 'emailPassword',
            email: this.email,
            password: this.password
          })
          this.$emit('loggedin')
          this.showToast({ text: 'Login successful', color: 'success' })
        } catch (error) {
          this.showToast({ text: error, color: 'error' })
        } finally {
          this.hideSpinner()
        }
      }
    },
    sendForgetEmail () {
      if (this.$refs.forgetForm.validate()) {
        this.showSpinner()
        this.sendResetPasswordEmail(this.forgetEmail)
          .then(() => this.showToast({
            text: 'A mail has been sent to your registered email id. Check the mail to reset your password.',
            timeout: 10000
          }))
          .catch((error) => {
            this.showToast({
              text: error.message,
              color: 'error',
              timeout: 10000
            })
          })
          .finally(() => {
            this.$refs.forgetForm.reset()
            this.forgetPasswordDialog = false
            this.hideSpinner()
          })
      }
    }
  }
}
</script>
