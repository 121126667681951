const isBlank = value => value === null || value === undefined || value === ''

export const required = value => !!value || 'Required'

export const requiredList = value => value.length > 0 || 'Required'

export const number = value => (isBlank(value) || !isNaN(value)) || 'Invalid number'

export const email = value => (isBlank(value) || /.+@.+\..+/.test(value)) || 'Email id is not valid'

export const mobileLength = value => (isBlank(value) || value.length === 10) || 'Mobile number has to be 10 digits'
